import createZustandContext from "Clutch/Utilities/Zustand/createZustandContext";

import {startTransition, useContext, useEffect, useState} from "react";
import {NavigationContext} from "Clutch/Contexts/Navigation/NavigationContext";
import useNavigationState from "Clutch/Hooks/useAppInstall/useNavigationState";

import {StorageAccessor} from "Clutch/Utilities/LocalStorageUtility";

import {useUserStore} from "Clutch/Stores/UserStore/UserStore";
import {TrackingContext} from "Contexts/TrackingContext/TrackingContext";
import {useVehicleStore} from "Clutch/Stores/VehicleStore";

import Retry from "Clutch/Utilities/Retry";
import GetPRP from "../Utilities/getPRP";
import buildMiscFacetContentPages from "../Utilities/miscFacetUtils";

import useNetwork from "Clutch/Hooks/useNetwork";
import {sendFeatureFlagEventsToGa, useFeatureFlag,} from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import {
    useUserSpecificProductDataStore
} from "Clutch/Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore";
import useRecentHistory from "Clutch/Hooks/useRecentHistory";
import {flushSync} from "react-dom";
import defer from "Clutch/Utilities/defer";
import {
    addSessionProperties,
    incrementSessionStoragePageViewCount,
    setIsEngagedSessionFired,
    trackEngagedSession,
  } from "Clutch/Utilities/EngagedSessionStorageUtility/EngagedSessionStorageUtility";
import {updateRecentSearches} from "Clutch/Layout/Header/RecentSearches/RecentSearches";

const initialUiState = {
    vehicleDrawer: false,
    compareToolModal: false,
    spaLoadCount: 0,
    spaLoading: false,
    loadingMoreProducts: false,
    productsLoaded: false,
    numProductsLoading: 0
};

function buildHelpers({set, get, api, initialProps}) {
    function setContext(update) {
        if (typeof update === "function") {
            set((state) => ({state: update(state.state)}));
        } else {
            set({state: update});
        }
    }

    function getDependencies() {
        return get()._dependencies;
    }

    function hideNavigation() {
        getDependencies().hideNavigation();
    }

    const getSpaFetchFunction = async () => {
        const module = await Retry(() => import("/Utilities/spaFetch"));
        return module.default;
    };

    return {
        hideNavigation,
        setContext,
        getDependencies,
        getSpaFetchFunction,
    };
}

function createPrpActionsSlice({set, get, api, initialProps}) {
    const {_dependencies, ...prpContext} = initialProps;
    const {setContext, getDependencies, getSpaFetchFunction} = buildHelpers({
        set,
        get,
        api,
        initialProps,
    });

    function applyProductResultPageDataMutations(productResultPageData) {
        buildMiscFacetContentPages(productResultPageData);
        return productResultPageData;
    }

    const getData = async () => {
        const {state: context} = get();

        let fetchPrecedingPages = false
        try {
            let previousPage = JSON.parse(window.localStorage.getItem('prpLastPageVisited'))

            if (previousPage?.data?.url === window.location.href) {
                fetchPrecedingPages = true

                if (previousPage?.data?.skuNumber) {
                    window.history.scrollRestoration = "manual"
                } else {
                    window.history.scrollRestoration = "auto"
                }
            }
        } catch (err) {
            console.log()
            console.error('There was an error getting the last prp visited data', err)
        }

        let response = await GetPRP(
            `${window.location.pathname}${window.location.search}`,
            fetchPrecedingPages
        );
        if (response) {
            window.history.replaceState(response, "", window.location.href);
            let pageContent = response.pageContent ?? context?.pageContent;
            if (pageContent && !pageContent.bannerImageUrl) {
                pageContent.bannerImageUrl = context?.pageContent?.bannerImageUrl;
            }
            setContext(response);
        }
    };

    const SavePrpHistoryWithSetTimeout = () => {
        const {state: context} = get();

        setTimeout(() => {
            StorageAccessor.sessionStorage.setObj(
                "cachedPrpData",
                {url: window.location.href, context},
                5
            );
        });
    };

    const handleOutOfLineData = () => {
        const {state: context} = get();

        if (typeof window.outOfLineData === "object") {
            if (window.outOfLineData.should404) {
                const err = new Error("out-of-line fetch received a 404");
                console.error(err);
                err.statusCode = 404;
                setContext((context) => ({
                    ...context,
                    should404: true,
                }));
            }
            if (window.outOfLineData.redirectUrl) {
                console.error(
                    `out-of-line data had a redirectUrl: ${window.outOfLineData.redirectUrl}`
                );
                window.location = window.outOfLineData.redirectUrl;
                return false;
            }

            try {
                if ((window.outOfLineData.searchProducts || []).length) {
                    if (
                        window.outOfLineData.searchProducts[0].skuBaseDetails?.imageLink
                    ) {
                        var img = new Image();
                        img.fetchpriority = "high";
                        img.loading = "eager";
                        img.src = `/_next/image?url=${encodeURIComponent(
                            `https:${window.outOfLineData.searchProducts[0].skuBaseDetails.imageLink}`
                        )}&w=256&q=75`;
                    }
                    if (
                        window.outOfLineData.searchProducts[1]?.skuBaseDetails?.imageLink
                    ) {
                        var img = new Image();
                        img.fetchpriority = "high";
                        img.loading = "eager";
                        img.src = `/_next/image?url=${encodeURIComponent(
                            `https:${window.outOfLineData.searchProducts[1].skuBaseDetails.imageLink}`
                        )}&w=256&q=75`;
                    }
                }
            } catch (e) {
                console.error(e);
            }

            window.history.replaceState(
                window.outOfLineData,
                "",
                window.location.href
            );

            let pageContent =
                window.outOfLineData?.pageContent ?? context?.pageContent;
            if (pageContent && !pageContent.bannerImageUrl) {
                pageContent.bannerImageUrl = context?.pageContent?.bannerImageUrl;
            }

            setContext({
                ...window.outOfLineData,
                pageContent,
            });
            return false;
        } else if (
            window.outOfLineData == "failed" ||
            window.outOfLineData == "off" ||
            typeof window.outOfLineData == "undefined"
        ) {
            getData();
            return false;
        }
        return true;
    };

    const GetAllFacetLinks = async (setWaitingState, callback) => {
        const {state: context} = get();

        const module = await Retry(() => import("/Utilities/updateSeeMoreLinks"));
        startTransition(() => {
            module
                .default(context, window.location.href, setWaitingState, () =>
                    startTransition(callback)
                )
                .then((newData) => {
                    startTransition(() => {
                        SetContextFromNewData(newData, false, "GetAllFacetLinks");
                    });
                });
        });
    };

    const SetContextFromNewData = (newData, forceUpdate, source) => {
        const {state: context} = get();
        const {addTrackedSkuBases, flushTrackedSkuBases} = getDependencies();

        if (typeof newData === "string" || newData === null) {
            window.alert(newData ?? "Something went wrong. Please try that again");
            return;
        }

        if (window.spaLoadId !== newData.spaLoadId && !forceUpdate) {
            return;
        }

        if (!newData?.pageContent?.bannerImageUrl) {
            newData.pageContent = {
                ...newData.pageContent,
                bannerImageUrl:
                    "https://content.speedwaymotors.com/OtherImages/brand-engine.jpg",
            };
        }

        window.history.replaceState(
            {...context, ...newData},
            "",
            window.location.href
        );

        const skippedCriticalAttributes = [
            ...(context?.skippedCriticalAttributes || []),
            ...(newData.skippedCriticalAttributes || []),
        ].filter((x) => x);
        const newValue = {
            ...context,
            ...newData,
            skippedCriticalAttributes,
        };
        applyProductResultPageDataMutations(newValue);
        setContext(newValue);
        console.log("updating context from", source)

        if (forceUpdate) {
            flushTrackedSkuBases();
        }
        addTrackedSkuBases(newValue.searchProducts.map((x) => x.skuBaseNumber));
    };

    async function getNextPageProductsModule() {
        const module = await Retry(() => import("/Utilities/nextPageProducts"));
        return module.default
    }

    const LoadLazyFacets = async () => {
        const {state: context} = get();

        const module = await Retry(() => import("/Utilities/lazyLoadFacets"));
        const {data, hasNewData} = await module.default(context);
        if (hasNewData) {
            SetContextFromNewData(data, false, "LoadLazyFacets");
        }

        defer(() => getSpaFetchFunction()); // load the spa fetch module, we're probably navigating soon...
    };

    const LoadLazyProducts = async () => {
        const {state: context} = get();
        const {getGuid} = getDependencies();

        const module = await Retry(() => import("/Utilities/lazyLoadProducts"));
        const newData = await module.default(
            context,
            getGuid()
        );
        SetContextFromNewData(newData, false, "LoadLazyProducts");
        //dont' need to get prices, this comes from product service anyway

        defer(() => getNextPageProductsModule())
    };

    const updatePrices = async (refreshAll = false) => {
        const {userGuid, isSpecialPriceEligible} = getDependencies();
        const {state: context} = get();

        if (!isSpecialPriceEligible) {
            return;
        }
        const module = await Retry(() => import("/Utilities/updatePrices"));
        const newData = await module.default(
            context,
            userGuid,
            refreshAll
        );
        SetContextFromNewData(newData, false, "updatePrices");
    };


    const LoadNextPageProducts = async (setWaitingState) => {
        const {state: context} = get();

        async function loadNextPageProductsInternalAsync() {
            const {SetLoadingMoreProducts} = get();

            let loadProductsTimeout = null;
            requestAnimationFrame(() => {
                loadProductsTimeout = setTimeout(() => {
                    SetLoadingMoreProducts(true);
                });
            });
            // todo we should probably try to load this sooner (on scroll)
            const getNextPageProducts = await getNextPageProductsModule()
            const newData = await getNextPageProducts(context, setWaitingState);
            SetContextFromNewData(newData, false, "LoadNextPageProducts");

            if (loadProductsTimeout) clearTimeout(loadProductsTimeout);

            SetLoadingMoreProducts(false);

            defer(() => {
                updatePrices();
            });
        }

        defer(loadNextPageProductsInternalAsync);
    };

    const Navigate = async (
        clickEvent,
        url,
        suspenseState,
        skippedCriticalAttributeId
    ) => {
        const {isBot, spaEnabled} = getDependencies();
        const {state: context, EndSpaLoad, StartSpaLoad, isMobile} = get();

        try {
            suspenseState(true);
            //if you want to resurrect spa functionality, here is the place to do it.

            if (spaEnabled && !isBot() && !url.includes("shop/all")) {
                if (!document.startViewTransition) {
                    StartSpaLoad();
                }

                if (clickEvent && typeof clickEvent.preventDefault === 'function') {
                    clickEvent.preventDefault();
                }

                window.onpopstate = () => {
                    transitionWrapperWithBrowserCheck(() => {
                        window.history.replaceState(
                            window.history.state,
                            "",
                            `${window.location.pathname + window.location.search}`
                        );
                        SetContextFromNewData(window.history.state, true, "onpopstate");
                        window.spaLoadId = window.history.state.spaLoadId;
                    });
                };

                const allSkippedCriticalAttributes = [
                    ...new Set([
                        ...context?.skippedCriticalAttributes,
                        skippedCriticalAttributeId,
                    ]),
                ].filter((x) => x);

                const spaFetch = await getSpaFetchFunction();
                let response = await spaFetch(
                    clickEvent,
                    url,
                    EndSpaLoad,
                    allSkippedCriticalAttributes
                );
                response.seeMoreHydrated = false;
                response.skippedCriticalAttributes = [
                    ...context?.skippedCriticalAttributes,
                    skippedCriticalAttributeId,
                ];
                window.history.replaceState(window.history.state, "", `${url}`);
                suspenseState(false);

                window.spaLoadId = (window.spaLoadId || 0) + 1;
                response.spaLoadId = window.spaLoadId;

                transitionWrapperWithBrowserCheck(() =>
                    SetContextFromNewData(response, true, "Navigate")
                );
            } else {
                window.location.href = url;
            }

        } catch (err) {
            console.error("spa load failure", err);
            EndSpaLoad();
            window.location.href = url;
        }
    };

    const transitionWrapperWithBrowserCheck = (func) => {
        if (document.startViewTransition) {
            document.startViewTransition(async () => {
                flushSync(() => {
                    func();
                });
            });
        } else {
            func();
        }
    };

    const LazyHydrateLateralFacets = async () => {
        try {
            const module = await Retry(() => import("/Utilities/lateralFacetsFetch"));

            const data = await module.default();

            if (data && data.title && data.links?.length) {
                setContext((state) => {
                    if (state?.bottomPageLinks) {
                        state.bottomPageLinks.push(data);
                    }
                    return state;
                });
            }
        } catch (err) {
            console.error("beans", err);
        }
    };

    const PushToRecentHistory = () => {
        const {state: context} = get();

        try {
            if (context?.isNoResultPage || context?.should404) {
                return;
            }
            let additionalDeets = {imageUrl: context?.representativeImageUrl};
            if (context?.isFacetResultPage) {
                additionalDeets.prpPageType = "facet";
            } else if (context?.isSearchPage) {
                additionalDeets.title = `Search for: ${context?.displayTitle}`;
                additionalDeets.prpPageType = "search";
                useRecentHistory.SetMostRecentProductList(
                    additionalDeets.prpPageType,
                    context?.displayTitle,
                    context?.attribution
                );
            } else {
                additionalDeets.prpPageType = "shop";
                useRecentHistory.SetMostRecentProductList(
                    additionalDeets.prpPageType,
                    window.location.pathname.replace("/shop/", "").replace("/", ""),
                    context?.attribution
                );
            }

            useRecentHistory.AddToRecentHistory(additionalDeets);
        } catch (err) {
            console.error("Error pushing to recent history in PRP", err);
        }
    };

    const AddSkippedCriticalAttribute = (id) => {
        setContext((state) => {
            state.skippedCriticalAttributes = [
                ...state.skippedCriticalAttributes,
                id,
            ];
            return state;
        });
    };

    console.log({prpContext});

    return {
        state: applyProductResultPageDataMutations(prpContext),

        updatePrices, // todo-- encapsulate!
        handleOutOfLineData,
        GetAllFacetLinks,
        LoadLazyFacets,
        LoadLazyProducts,
        LoadNextPageProducts,
        Navigate,
        AddSkippedCriticalAttribute,
        LazyHydrateLateralFacets,
        PushToRecentHistory,
        SavePrpHistoryWithSetTimeout
    };
}

function createUiActionsSlice({set, get, api, initialProps}) {
    const {setContext, hideNavigation, getSpaFetchFunction} = buildHelpers({
        set,
        get,
        api,
        initialProps,
    });

    function setUiState(uiStateFunc) {
        // zustand auto merges state updates-- how cool is that?!
        set((state) => ({uiState: uiStateFunc(state.uiState)}));
    }

    const StartSpaLoad = () => {
        setUiState((state) => {
            return {...state, spaLoading: true};
        });
    };

    const EndSpaLoad = () => {
        setUiState((state) => {
            return {...state, spaLoading: false, numProductsLoading: 0};
        });
        hideNavigation();
    };

    const SetLoadingMoreProducts = (loading) => {
        setUiState((state) => {
            return {...state, loadingMoreProducts: loading};
        });
    };

    const OpenSidebarDrawer = () => {
        setUiState((state) => {
            return {...state, sidebarDrawerOpen: true};
        });
        defer(() => getSpaFetchFunction()); // load the spa fetch module, we're probably navigating soon...
    };

    const SetProductsLoaded = (loaded) => {
        setUiState((state) => {
            return {
                ...state,
                productsLoaded: loaded,
            };
        });
    };

    const CloseSidebarDrawer = () => {
        setUiState((state) => {
            return {...state, sidebarDrawerOpen: false};
        });
    };

    const SetNumProductsLoading = (numProducts) => {
        setUiState((x) => {
            return {
                ...x,
                numProductsLoading: numProducts,
            };
        });
    };

    const OpenCompareToolModal = (skuBaseNumber) => {
        setContext((state) => {
            state.skuBaseToCompare = skuBaseNumber;
            return state;
        });
        setUiState((state) => {
            return {
                ...state,
                compareToolModal: true,
            };
        });
    };

    const CloseCompareToolModal = () => {
        setContext((state) => {
            state.skuBaseToCompare = null;
            return state;
        });
        setUiState((state) => {
            return {
                ...state,
                compareToolModal: false,
            };
        });
    };

    return {
        // ui related state
        uiState: initialUiState,

        // ui related actions
        OpenSidebarDrawer,
        CloseSidebarDrawer,
        SetLoadingMoreProducts,
        SetProductsLoaded,
        StartSpaLoad,
        EndSpaLoad,
        OpenCompareToolModal,
        CloseCompareToolModal,
        SetNumProductsLoading
    };
}

function prpZustandStoreInitializer(storeArgs) {
    if (!storeArgs.initialProps) {
        console.warn("storeArgs.initialProps is null or undefined")
    }

    return {
        _dependencies: storeArgs.initialProps?._dependencies,

        ...createUiActionsSlice(storeArgs),
        ...createPrpActionsSlice(storeArgs),
    };
}

const prpContext = createZustandContext(prpZustandStoreInitializer, {
    missingSelectorBehavior: "quiet for now", // "warn", (removal of this prop throws hard errors)
});
prpContext.name = "Prp";

function PdpSkeletonWiring() {
    const navigationContext = useContext(NavigationContext);
    useEffect(() => {
        window.addEventListener("pageshow", () =>
            navigationContext.setPdpSkeleton(false)
        );
    }, []);
}

export const usePrpStore = prpContext.useStore;

function PrpEffects() {
    const PushToRecentHistory = usePrpStore((x) => x.PushToRecentHistory);
    const SetContextFromNewData = usePrpStore((x) => x.SetContextFromNewData);
    const LoadLazyProducts = usePrpStore((x) => x.LoadLazyProducts);
    const LoadLazyFacets = usePrpStore((x) => x.LoadLazyFacets);
    const handleOutOfLineData = usePrpStore((x) => x.handleOutOfLineData);
    const updatePrices = usePrpStore((x) => x.updatePrices);
    const skeleton = usePrpStore((x) => x.state.skeleton);
    const misoId = usePrpStore((x) => x.state.misoId);
    const spaLoadId = usePrpStore((x) => x.state.spaLoadId);
    const context = usePrpStore((x) => x.state); // todo split up (to reduce rerenders of these effects)
    const sidebarDrawerOpen = usePrpStore((x) => x.uiState.sidebarDrawerOpen);
    const setAreLazyUpdatesAllowed = useUserSpecificProductDataStore(
        (x) => x.setAreUpdatesAllowed
    );
    const {hideNavigation} = useNavigationState();
    const {setMisoId} = useContext(TrackingContext);
    const hydrated = useUserStore((x) => x.context.hydrated);
    const isBot = useUserStore((x) => x.context.isBot);
    const userVin = useUserStore((x) => x.context.userVin);
    const userGuid = useUserStore((x) => x.context.userGuid);
    const setSelectedFacets = useVehicleStore(x => x.setSelectedFacets)
    const setFitmentDetailsFromFitmentFacet = useUserSpecificProductDataStore(x => x.setFitmentDetailsFromFitmentFacet)
    const setFitmentDetailsFromSelectedFacets = useUserSpecificProductDataStore(x => x.setFitmentDetailsFromSelectedFacets)
    const removeVehicleLocalStorage = useVehicleStore(x => x.removeVehicleLocalStorage)
    const setMakerVehiclePartTypeFunction = useVehicleStore(x => x.setMakerVehiclePartTypeFunction)
    const navigationContext = useContext(NavigationContext);

    const {segmentReady} = useContext(TrackingContext);
    const spaEnabled = useFeatureFlag("PRP_SPA");
    const SEARCHTERMPLACEHOLDER = `Searching...`

    useEffect(() => {
        navigationContext.setPageType("PRP");
    }, [])

    useEffect(() => {
        if (!skeleton) {
            setTimeout(
                () =>
                    window.dispatchEvent(new CustomEvent("clarityautomate.initialized")),
                200
            );
            PushToRecentHistory();

            // setTimeout(() => {
            LoadLazyFacets();
            // })

            if (misoId) {
                setMisoId(misoId);
            }
        }
    }, [skeleton]);

    useEffect(() => {
        try {
            if (
                hydrated &&
                context?.instrumentation?.featureFlags?.length &&
                !isBot()
            ) {
                context?.instrumentation?.featureFlags?.map((x) => {
                    if (
                        x.featureFlagName &&
                        (x.treatment == false || x.treatment == true)
                    ) {
                        sendFeatureFlagEventsToGa({
                            name: x.featureFlagName,
                            value: x.treatment,
                        });
                    }
                });
            }
        } catch (err) {
            console.error(err);
        }
    }, [context?.instrumentation?.featureFlags, hydrated]);

    useEffect(() => {
        if (window.startOutLineFetch) {
            console.log(
                "out-line-fetch difference",
                new Date().getTime() - window.startOutLineFetch
            );
        }

        if (typeof window !== "undefined") {
            try {
                window.removeEventListener("outOfLineDataReady", () => {
                });
            } catch (err) {
                console.error("error removing event listener", err);
            }
            if (skeleton) {
                if (handleOutOfLineData()) {
                    window.addEventListener("outOfLineDataReady", () => {
                        handleOutOfLineData();
                    });
                }
            }
        }
    }, []);

    useEffect(() => {
        if (context?.isSearchPage && context?.displayTitle !== SEARCHTERMPLACEHOLDER) {
            updateRecentSearches(context?.displayTitle);
        }
    }, [context?.displayTitle]);

    useEffect(() => {
        if (context?.instrumentation?.metrics?.searchProviderName) {
            console.log(
                `SearchProvider-${context?.instrumentation?.metrics?.searchProviderName}`
            );
        }
    }, [context?.instrumentation?.metrics?.searchProviderName]);

    useEffect(() => {
        if (spaEnabled) {
            window.onpopstate = () => {
                window.history.replaceState(
                    window.history.state,
                    "",
                    `${window.location.pathname + window.location.search}`
                );
                SetContextFromNewData(window.history.state, true, "spaEnabledHook");
                window.spaLoadId = window.history.state.spaLoadId;
                hideNavigation();
            };
        }
    }, [spaEnabled]);

    useEffect(() => {
        if (hydrated && !context?.skeleton) {
            updatePrices(true);
        }
    }, [hydrated, context?.skeleton]);

    function LoadLazyProductRelatedData() {
        defer(() => {
            //still need to call this for the first 10 non lazy products
            updatePrices();

            LoadLazyProducts()
            setAreLazyUpdatesAllowed(true);
        });
    }

    useEffect(() => {
        if (context?.isPartialMatch) {
            console.log("Partial search match result");
        }
    }, [context?.isPartialMatch]);

    useEffect(() => {
        if (spaLoadId) {
            //GA page view
            //BR page view
            //segment page view?

            let pageType;

            if (context?.isFacetResultPage) pageType = "Facet Result Page";
            else if (context?.isNoResultPage) pageType = "No Results Page";
            else pageType = "Product Results Page";

            if (segmentReady && (context?.isNoResultPage || context?.isPartialMatch) && !context?.skeleton) {
                analytics.track("NRP Reached", {
                    user_vin: `${userVin()}`,
                    web_user_guid: `${userGuid}`,
                    query: context?.displayTitle,
                    page_type: "NRP",
                });
            }

            PushToRecentHistory();

            //nothing special here
            LoadLazyFacets();

            window.dispatchEvent(new CustomEvent("clarityautomate.initialized"));
        }
    }, [spaLoadId]);

    useEffect(() => {
        // only update product data when the drawer is closed (if we don't it costs a lot for desktop facet selection)
        const updatesAllowed = !sidebarDrawerOpen;

        // wait until the side bar is closed (it's always closed on desktop)
        // before we use up render cycles on the main product stuff
        if (spaLoadId && !sidebarDrawerOpen) {
            defer(() => {
                LoadLazyProductRelatedData()
            });
        } else {
            setAreLazyUpdatesAllowed(updatesAllowed)
        }
    }, [spaLoadId, sidebarDrawerOpen]);


    useEffect(() => {
        try {
            if (context.selectedFacetOptions) {
                let selectedPartFacet = context
                    .selectedFacetOptions
                    .find(f => f.displayText === "Part");

                if (selectedPartFacet) {
                    let id = selectedPartFacet.searchResponseFacetOptions[0].id;

                    setMakerVehiclePartTypeFunction(id);
                }

                setSelectedFacets({searchQuery: context.searchQuery, selectedFacetOptions: context.selectedFacetOptions});
            }
        } catch (e) {
            console.error("Something went wrong while hydrating the Maker vehicle function", e)
        }
    }, [context.selectedFacetOptions, context.searchQuery]);

    useEffect(() => {
        if (context.selectedFacetOptions) {
            let fitmentFacet = getFitmentFacetFromSelectedFacets();

            if (fitmentFacet) {
                setFitmentDetailsFromFitmentFacet(fitmentFacet);
            }
            if (setFitmentDetailsFromSelectedFacets)
                setFitmentDetailsFromSelectedFacets(context.selectedFacetOptions)
        }
    }, [context.selectedFacetOptions, setFitmentDetailsFromSelectedFacets])

    function getFitmentFacetFromSelectedFacets() {
        return context?.selectedFacetOptions?.find(
            (selectedFacet) =>
                selectedFacet.displayText === "Race Type" ||
                selectedFacet.displayText === "Fitment"
        );
    }

    useNetwork((data) => { //THIS WAITS UNTIL WE HAVE DATA FROM THE PRP REQUEST - IT WILL FIRE ON THE INITIAL PAGE LOAD AND ANY SPA LOADS - window.pageViewOverrideEnabled is the flag here
        if (window?.pageViewOverrideEnabled && segmentReady && misoId) {

            let properties = {
                speed: `${data.speed}`,
                network: `${data.network}`,
                cpu: `${data.cpu}`,
                memory: `${data.memory}`,
                dataSaver: `${data.dataSaver}`,
            };

            properties = {
                ...properties,
                ...(context?.attribution || {})
            }

            properties = addSessionProperties(properties);

            incrementSessionStoragePageViewCount();
            trackEngagedSession();

            analytics.page(properties);

            setIsEngagedSessionFired();
        }
    }, [segmentReady, misoId]);

    useNetwork((data) => { //THIS ONLY FIRST ON SPA LOADS - CLUTCH HANDLES THE INITIAL LOAD - window.pageViewOverrideEnabled is the flag here
            if (!window?.pageViewOverrideEnabled && spaLoadId && segmentReady) {

                var properties = {
                    speed: `${data.speed}`,
                    network: `${data.network}`,
                    cpu: `${data.cpu}`,
                    memory: `${data.memory}`,
                    dataSaver: `${data.dataSaver}`,
                    search_provider: `${context?.instrumentation?.metrics?.searchProviderName}`,
                }

                properties = {
                    ...properties,
                    ...(context?.attribution || {})
                }

                properties = addSessionProperties(properties);

                incrementSessionStoragePageViewCount();
                trackEngagedSession();

                analytics.page(properties);

                setIsEngagedSessionFired();
            }
        },
        [spaLoadId, segmentReady]
    );
}

prpContext.Wiring = () => {
    return (
        <>
            <PrpEffects/>
            <PdpSkeletonWiring/>
        </>
    );
};

// todo, move this hook into clutch
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        function detectIsMobile() {
            if (typeof window !== undefined && window.innerWidth < 835) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }

        detectIsMobile();
        window.addEventListener("orientationchange", detectIsMobile);
        return () =>
            window.removeEventListener("orientationchange", detectIsMobile);
    }, []);
    return isMobile;
};

prpContext.useSynchronizedProps = () => {
    const {hideNavigation} = useNavigationState();

    const {segmentReady, setMisoId} = useContext(TrackingContext);
    const vehicleContext = useVehicleStore(x => x.context);
    const isBot = useUserStore((x) => x.context.isBot);
    const userGuid = useUserStore((x) => x.context.userGuid);
    const isSpecialPriceEligible = useUserStore((x) => x.context.isSpecialPriceEligible);
    const getGuid = useUserStore((x) => x.context.getGuid);
    const spaEnabled = useFeatureFlag("PRP_SPA");

    const isMobile = useIsMobile();

    const flushTrackedSkuBases = useUserSpecificProductDataStore((x) => x.flush);
    const addTrackedSkuBases = useUserSpecificProductDataStore(
        (x) => x.addTrackedSkuBases
    );
    const addFitmentDataForSkuBase = useUserSpecificProductDataStore(
        (x) => x.addFitmentDataForSkuBase
    );
    const setAreLazyUpdatesAllowed = useUserSpecificProductDataStore(
        (x) => x.setAreUpdatesAllowed
    );

    return {
        _dependencies: {
            hideNavigation,
            isBot,
            userGuid,
            getGuid,
            isSpecialPriceEligible,
            vehicleContext,
            segmentReady,
            setMisoId,
            spaEnabled,
            flushTrackedSkuBases,
            addTrackedSkuBases,
            addFitmentDataForSkuBase,
            setAreLazyUpdatesAllowed
        },
        isMobile,
    };
};

export const PrpProvider = ({
                                initialState,
                                children,
                                initialParams, // never saw this being used
                            }) => {
    return (
        <prpContext.Provider initialProps={initialState}>
            {children}
        </prpContext.Provider>
    );
};
